import { useEffect } from "react";

export const useEventListener = <K extends keyof WindowEventMap>(
  type: K,
  handler: (ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions | undefined
): void => {
  useEffect(() => {
    const listener = (ev: WindowEventMap[K]): any => handler(ev);

    window.addEventListener(type, listener, options);

    return () => {
      window.removeEventListener(type, listener, options);
    };
  }, [type, handler]);
};
