import { styled, pink, angle as fgAngle } from "../../styles";

export interface ShadowedBoxProps {
  angle?: number;
  bgAngle?: number;
  bgPosition?: [number, number, number, number];
}

export const ShadowedBox = styled.div<ShadowedBoxProps>`
  &::after {
    content: "";

    position: absolute;

    transform: translateZ(-1px) rotate(${({ bgAngle }) => bgAngle || 2.5}deg);
    transform-style: preserve-3d;
    background-color: ${pink};

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: -1;
  }

  transform-style: preserve-3d;
  z-index: 1;
  position: relative;
  background-size: cover;

  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;

  ${({ angle }) => fgAngle(angle ? angle : 0)};
`;

ShadowedBox.defaultProps = {
  angle: 0,
  bgAngle: 2.5
};
