import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export interface SeoProps {
  title?: string;
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
  article?: any;
}

export const Seo: React.FC<SeoProps> = ({
  description,
  lang,
  meta,
  keywords,
  title,
  article
}) => {
  const {
    site: { siteMetadata: seo }
  } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          name

          siteUrl
          image
          social {
            twitter
          }
          google {
            verification
          }
        }
      }
    }
  `);

  const metaDescription = description || seo.description;
  const image = article && article.image ? article.image : seo.image;
  const url = article && article.url ? article.url : undefined;
  const isArticle = article !== undefined;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${seo.title}`}
      defaultTitle={seo.title}
      meta={meta}
    >
      {/* General tags */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={image} />
      <meta
        name="keywords"
        content={
          keywords && keywords.length > 0 ? keywords.join(`, `) : undefined
        }
      />
      <meta name="robots" content="index, follow" />
      {seo.google.verification && seo.google.verification.length > 0 && (
        <meta
          name="google-site-verification"
          content={seo.google.verification}
        />
      )}
      {url && <link rel="canonical" href={url} />}
      {/* OpenGraph tags */}
      {url && <meta property="og:url" content={url} />}
      {isArticle ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={seo.social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <link
        rel="alternate"
        href="https://germanwahnsinn.de/"
        hrefLang="x-default"
      />
      <link
        rel="alternate"
        href="https://germanwahnsinn.de/en/"
        hrefLang="en"
      />
      <link rel="alternate" href="https://germanwahnsinn.de/" hrefLang="de" />
      <script
        src="https://cloud.ccm19.de/app.js?apiKey=0cfc727bdb7500bfaa59393f82f70a27f1ae656cffab1df3&amp;domain=65c362a9261f4be2f70f0a62"
        referrerPolicy="origin"
      ></script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-726861027"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());

          gtag('config', 'AW-726861027');
        `}
      </script>
      <script>
        {`
          gtag('event', 'conversion', {'send_to': 'AW-726861027/GX7kCMDZncoYEOOJzNoC'});
        `}
      </script>
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};
