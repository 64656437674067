import React from "react";
import { styled, pink, fonts } from "../../styles";

export const ContentOuter = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  min-width: 320px;
  max-width: 1360px;

  position: relative;
  overflow: visible;

  a {
    font-family: ${fonts.vario};
    font-variation-settings: "wght" 520;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:hover,
    &.active {
      color: ${pink};
      animation: wahnsinn 0.3s linear 0s infinite;
    }
  }
`;

export const ContentInner = styled.div`
  position: relative;
  margin: 0 40px;
`;

export const Content: React.SFC<{}> = ({ children, ...props }) => (
  <ContentOuter {...props}>
    <ContentInner>{children}</ContentInner>
  </ContentOuter>
);
