import { useState, useLayoutEffect } from "react";

export const useWidth = (): number => {
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  useLayoutEffect(() => {
    let animationFrame: number | undefined;

    function handleResize() {
      if (typeof window !== "undefined") {
        // If there's a timer, cancel it
        if (!animationFrame) {
          animationFrame = window.requestAnimationFrame(() => {
            setWidth(window.innerWidth);

            animationFrame = undefined;
          });
        }
      }
    }

    handleResize();

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      return () => {
        if (animationFrame) {
          window.cancelAnimationFrame(animationFrame);
        }

        window.removeEventListener("resize", handleResize);
      };
    }
  });

  return width;
};
