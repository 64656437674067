import { useState } from "react";

export const useToggle = (
  initialValue: boolean = true
): [boolean, () => void] => {
  const [value, setToggle] = useState(initialValue);

  return [
    value,
    () => {
      setToggle(!value);
    }
  ];
};
