import React from "react";
// @ts-ignore
import { I18nProvider } from "@lingui/react";
// import { Head } from "gatsby";
import { i18n, getLanguageFromPath } from "../../i18n";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Sidebar, SidebarProvider } from "../Sidebar";
import { Seo, SeoProps } from "../Seo";
import { GlobalStyles, styled, media, fonts } from "../../styles";
import { smoothScrollToTop } from "../../utils";

interface PageProps extends SeoProps {
  title: string;
  seoTitle?: string;
  header?: any;
  footer?: any;
  footerProps?: any;
  sideLabel?: boolean;
  bgPaper?: null | "dot" | "graph" | "square";
  isModal?: boolean;
  backLink?: string;
  headerSkull?: string;
  mobileHeaderFixed?: boolean;
}

export const Page: React.FC<PageProps> = ({
  children,
  title,
  seoTitle,
  description,
  keywords,
  header,
  footer,
  footerProps = {},
  sideLabel,
  bgPaper,
  isModal,
  backLink,
  headerSkull,
  mobileHeaderFixed,
  ...seoProps
}) => {
  let lang = "de";

  if (
    typeof window !== "undefined" &&
    window.location &&
    window.location.pathname
  ) {
    lang = getLanguageFromPath(window.location.pathname);

    // @ts-ignore
    if (i18n.locale !== lang) {
      i18n.activate(lang);
    }
  }

  return (
    <SidebarProvider>
      <GlobalStyles bgPaper={bgPaper} />
      <Seo
        title={seoTitle || title}
        description={description}
        keywords={keywords}
        lang={lang}
        {...seoProps}
      />
      {/* bgPaper && <BgPaper/> */}

      <Sidebar />

      {sideLabel !== false && (
        <SideLabel>
          <a href="#top" onClick={smoothScrollToTop}>
            ;
          </a>
        </SideLabel>
      )}

      {header || (
        <Header
          title={title}
          isModal={isModal}
          backLink={backLink}
          headerSkull={headerSkull}
          mobileHeaderFixed={mobileHeaderFixed || false}
        />
      )}

      <main>{children}</main>

      {footer === false ? null : <Footer {...footerProps} />}
    </SidebarProvider>
  );
};

Page.defaultProps = {
  sideLabel: true,
  bgPaper: null,
  isModal: false
};

const SideLabel = styled.span`
  position: fixed;
  top: 90vh;
  right: 30px;
  transform: rotate(0deg);
  writing-mode: vertical-rl;
  z-index: 100;
  display: none;

  a {
    font-variation-settings: "wght" 520;
    font-family: ${fonts.vario};
    text-transform: uppercase;
    font-size: 24px;

    background-color: rgba(255, 255, 255, 1);
    border-radius: 2px;
    border: 1px solid transparent;
    padding-left: 5px;

    width: 42px;
    height: 42px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${media.tablet} {
    display: inline-block;
  }
`;
/*
export const BgPaper = createGlobalStyle<{
  bgPaper?: null | "dot" | "graph" | "square";
}>`
  body {
    ${media.tablet} {
      background-repeat: repeat;
      background-image: ${({ bgPaper }) =>
        bgPaper ? `url("/paper_${bgPaper}.svg")` : "none"};
    }

    ${media.landscape} {
      background-image: none;
    }
  }
`;
*/
