import React from "react";
import { styled, black } from "../../styles";
import { useLockBodyScroll, useKeyPress } from "../../hooks";

const ModalBackground = styled.div<{}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  transition: 0.5s;
  z-index: 1;
`;

const ModalContainer = styled.div`
  align-self: center;
  justify-self: center;
  background: white;
  width: 90%;
  max-width: 800px;
  max-height: 800px;
  position: relative;
  overflow: scroll;
  color: ${black};
  box-shadow: 0 0 3em ${black};
  margin: 0 auto;

  @media (min-width: 480px) {
    margin: 2rem;
  }
`;

export const Modal: React.FC<{ closeModal: () => void }> = ({
  closeModal,
  children
}) => {
  const KEYCODE_ESC = 27;

  // Call hook to lock body scroll
  useLockBodyScroll();
  useKeyPress(KEYCODE_ESC, closeModal, []);

  return (
    <ModalBackground onClick={closeModal}>
      <ModalContainer
        onClick={event => event.stopPropagation()}
        role="dialog"
        ariaModal="true"
      >
        {children}
      </ModalContainer>
    </ModalBackground>
  );
};
