import { useState, useEffect } from "react";

export const useInfiniteScroll = (callback: (cb: () => void) => void) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }

    callback(() => {
      setIsFetching(false);
    });
  }, [isFetching]);

  function handleScroll() {
    if (
      window.innerHeight + window.pageYOffset <
      document.body.offsetHeight - window.innerHeight * 0.5
    ) {
      return;
    }

    setIsFetching(true);
  }

  return [isFetching, setIsFetching];
};
