import React from "react";
import posed from "react-pose";
import { styled, media, fonts, pink } from "../../styles";
import { BurgerMenu } from "./components/BurgerMenu";
import { Skull } from "./components/Skull";
import { IconLink } from "../IconLink";
import { useScrollPosition } from "../../hooks";
import { smoothScrollToTop } from "../../utils";
import mobileHeaderImg from "./assets/mobile_header.svg";

interface HeaderProps {
  title: string;
  isModal?: boolean;
  backLink?: string;
  headerSkull?: string;
  mobileHeaderFixed?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  title,
  isModal = false,
  backLink = "/",
  headerSkull,
  mobileHeaderFixed
}) => {
  const { y: scrollY } = useScrollPosition();

  const openTitle = scrollY > 100 || mobileHeaderFixed;

  return (
    <>
      <BurgerMenu alt="Open navigation" />

      <MobileHeader
        bg={mobileHeaderImg}
        pose={openTitle ? "enter" : "exit"}
        ariaHidden={openTitle}
      >
        <MobileTitle onClick={smoothScrollToTop}>{title}</MobileTitle>
      </MobileHeader>

      <HeaderContainer>
        {children || isModal ? (
          <Close to={backLink}>×</Close>
        ) : (
          <HeaderIconBar>
            {headerSkull && !isModal && <Skull type={headerSkull} />}
            {title === "About" && (
              <>
                <IconLink
                  to="https://fb.com/germanwahnsinn"
                  target="_blank"
                  rel="noopener"
                >
                  _
                </IconLink>
                <IconLink
                  to="https://www.instagram.com/germanwahnsinn/"
                  target="_blank"
                  rel="noopener"
                >
                  §
                </IconLink>
                <IconLink
                  to="https://twitter.com/germanwahnsinn"
                  target="_blank"
                  rel="noopener"
                >
                  #
                </IconLink>
                <IconLink
                  to="https://open.spotify.com/artist/2NnaatfEYKcqBk4Qr5OtvP"
                  target="_blank"
                  rel="noopener"
                >
                  *
                </IconLink>
              </>
            )}
          </HeaderIconBar>
        )}
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
  margin-right: 12px;
  position: fixed;
  right: 0;
  z-index: 199;

  ${media.tablet} {
    margin-right: 30px;
  }
`;

const HeaderIconBar = styled.div`
  display: flex;

  ${IconLink} {
    display: none;
  }

  ${media.tablet} {
    ${IconLink} {
      display: inherit;
    }
  }
`;

const MobileHeaderPose = posed.div({
  enter: {
    y: 0,
    top: 0,
    transition: { duration: 250, ease: "linear" }
  },
  exit: {
    top: 0,
    y: "-150%"
  }
});

const MobileHeader = styled(MobileHeaderPose)`
  display: block;
  position: fixed;
  top: -150%;
  left: 0;
  right: 0;

  z-index: 180;

  min-width: 100%;
  min-height: 100px;

  padding: 0 32px;

  background-image: ${({ bg }) => `url('${bg}')`};
  background-size: cover;

  ${media.tablet} {
    display: none;
  }

  ${media.landscape} {
    display: block;
  }
`;

const MobileTitle = styled.h2`
  margin-left: 30px;
  margin-top: 40px;
  margin-right: 30px;
  color: ${pink};
  width: auto;
  font-size: 27px;
  font-family: ${fonts.nittiGroteskBold};
  cursor: pointer;

  ${media.landscape} {
    margin-left: 30px;
  }

  ${media.tablet} {
    margin-left: 60px;
  }
`;

const Close = styled(IconLink)`
  font-size: 36px;
  padding-bottom: 5px;
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    margin-right: 0;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 2px;
    border: 1px solid transparent;
  }

  ${media.tablet} {
    font-size: 40px;
  }
`;
