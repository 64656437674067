import { useState, useLayoutEffect } from "react";

interface ScrollPosition {
  x: number;
  y: number;
}

export const useScrollPosition = (): ScrollPosition => {
  const [position, setScrollPosition] = useState<ScrollPosition>({
    x: 0,
    y: 0
  });

  useLayoutEffect(() => {
    let animationFrame: number | undefined;

    const handleScroll = function handle() {
      if (typeof window !== "undefined") {
        if (!animationFrame) {
          animationFrame = window.requestAnimationFrame(() => {
            setScrollPosition({ x: window.scrollX, y: window.scrollY });

            animationFrame = undefined;
          });
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, {
        passive: true
      });

      return () => {
        if (animationFrame) {
          window.cancelAnimationFrame(animationFrame);
        }

        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return position;
};

export function useScrollXPosition(): number {
  const { x } = useScrollPosition();

  return x;
}

export function useScrollYPosition(): number {
  const { y } = useScrollPosition();

  return y;
}
