import { useEventListener } from "./useEventListener";
import { useCallback } from "react";

export const useKeyPress = (
  keyCode: number,
  handler: (event: KeyboardEvent) => void,
  deps: any[],
  options?: boolean | AddEventListenerOptions | undefined
) => {
  const finalDeps = [...deps, keyCode];

  const keydownHandler = useCallback((event: KeyboardEvent) => {
    if (event.keyCode === keyCode) {
      handler(event);
    }
  }, finalDeps);

  useEventListener("keydown", keydownHandler, options);
};
