import {
  styled,
  style,
  css,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  display,
  DisplayProps,
  variant,
  width,
  WidthProps,
  fontSize,
  FontSizeProps
} from "../../styles";

const columnCount = style({
  prop: "columns",
  cssProperty: "columnCount"
});

const columnGap = style({
  prop: "columngap",
  cssProperty: "columnGap"
});

const listStyle = variant({
  key: "listStyles"
});

interface ListAttrProps {
  ordered?: boolean;
}

export interface ListProps
  extends ListAttrProps,
    SpaceProps,
    DisplayProps,
    TextAlignProps,
    WidthProps,
    FontSizeProps {
  dense?: boolean;
  columns?: number | number[];
  columnGap?: string | string[];
  inline?: boolean;
}

export const List = styled.ul.attrs<ListAttrProps>(({ ordered }) => ({
  as: ordered ? "ol" : "ul"
}))<ListProps>`
  ${columnCount}
  ${columnGap}
  ${space}
  ${display}
  ${textAlign}
  ${listStyle}
  ${width}
  ${fontSize}

  ${({ dense }) =>
    dense
      ? css`
          list-style-position: inside;
          padding-left: 0;
        `
      : undefined}

  ${({ inline }) =>
    inline
      ? css`
          display: flex;
          flex-wrap: wrap;
        `
      : undefined}
`;

List.defaultProps = {
  ordered: false,
  columns: 1,
  dense: false
};
