import { styled, fonts } from "../../styles";
import { Link, LinkProps } from "../Link";

export interface IconLinkProps extends LinkProps {
  to: string | undefined;
}

export const IconLink = styled(Link)<IconLinkProps>`
  text-transform: uppercase;
  font-variation-settings: "wght" 520;
  text-rendering: unset;

  font-family: ${fonts.vario};
  font-size: 40px;

  &:hover {
    animation: wahnsinn 0.3s linear 0s infinite;
  }
`;
