import GatsbyImage, { GatsbyImageProps } from "gatsby-image";
import {
  styled,
  width,
  WidthProps,
  height,
  HeightProps,
  space,
  SpaceProps,
  maxWidth,
  MaxWidthProps,
  maxHeight,
  MaxHeightProps,
  zIndex,
  ZIndexProps,
  position,
  PositionProps,
  overflow,
  OverflowProps
} from "../../styles";

interface ImageAttrs {
  src?: string;
}

export interface ImageProps
  extends GatsbyImageProps,
    SpaceProps,
    WidthProps,
    HeightProps,
    ImageAttrs,
    MaxWidthProps,
    MaxHeightProps,
    ZIndexProps,
    PositionProps,
    OverflowProps {}

export const Image = styled(GatsbyImage).attrs<ImageAttrs>(({ src }) => ({
  as: src ? "img" : GatsbyImage,
  style: {}
}))<ImageProps>`
  max-width: 100%;
  height: auto;

  ${space}
  ${width}
  ${height}
  ${maxWidth}
  ${maxHeight}
  ${zIndex}
  ${overflow}
  ${position}
`;
