import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import { styled, media, angle } from "../../styles";
import { IconLink } from "../IconLink";
import { Modal } from "../Modal";
import RocketImg from "../../views/HomePage/assets/Rocket_ani.inline.svg";

export const NewsletterModal: React.FC<{}> = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = (event: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
    }

    setModalOpen(false);
  };

  const openModal = (event: React.MouseEvent) => {
    setModalOpen(true);
  };

  return (
    <>
      <strong>
        <a onClick={openModal}>Newsletter</a>
      </strong>

      {modalOpen && (
        <Modal closeModal={closeModal}>
          <ModalContent>
            <ModalClose to={undefined} onClick={closeModal}>
              x
            </ModalClose>
            <ModalHeading>
              <Trans>
                Anmeldung <br />
                zum Newsletter
              </Trans>
            </ModalHeading>

            <ModalCopy>
              <Trans>
                Immer das Neueste aus dem Hause Wahnsinn, handverlesen und per
                digitaler Flaschenpost! Trag deine Adresse ein und wir heißen
                dich herzlich willkommen im Club der hervorragend informierten
                Menschen. Wir versprechen auch hoch und heilig, dir nicht das
                Postfach voll zu spammen.
              </Trans>
            </ModalCopy>

            <MailChimpForm onSuccess={closeModal} />

            <Rocket />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 1.5rem;

  ${media.tablet} {
    margin: 2rem 4rem;
  }
`;

const ModalClose = styled(IconLink)`
  font-size: 36px;

  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: -1rem;
  right: -1rem;

  ${media.tablet} {
    top: -2rem;
    right: -4rem;
  }
`;

const ModalHeading = styled.h2`
  ${media.tablet} {
    margin-bottom: 4rem;
    margin-left: -1rem;
    ${angle(-4)};
  }
`;

const ModalCopy = styled.p`
  ${media.tablet} {
    width: 60%;
    margin-bottom: 4rem;
    ${angle(-2)};
  }
`;

const Rocket = styled(RocketImg)`
  display: none;

  ${media.tablet} {
    display: block;
    position: absolute;
    width: 30%;
    bottom: 15%;
    right: 0;
  }
`;

const url =
  "//germanwahnsinn.us13.list-manage.com/subscribe/post-json?u=c7ae6d4d736e2e794c0832e24&amp;id=54bcbdb93e";

// simplest form (only email)
const MailChimpForm: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const [email, setEmail] = useState<string | undefined>();
  const [state, setState] = useState<string | undefined>();

  const handleSubmit = (event: React.FormEvent) => {
    setState("sending");
    event.preventDefault();

    const data = {
      EMAIL: email
    };
    const params = toQueryString(data);

    jsonp(
      `${url}&${params}`,
      {
        param: "c"
      },
      (error, data) => {
        if (error) {
          setState(`Error: ${error}`);
        } else if (data.result !== "success") {
          setState(`Error: ${data.msg}`);
        } else {
          onSuccess();
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      {state && <p>{state}</p>}

      <EmailInput
        type="email"
        onChange={e => setEmail(e.target.value)}
        placeholder="Email address"
        name="email"
        required
      />
      <SubmitButton type="submit" disabled={!!state}>
        <Trans>Make it so!</Trans>
      </SubmitButton>
    </form>
  );
};

const EmailInput = styled.input`
  margin: 0 1rem 0 0;
  width: 100%;

  ${media.tablet} {
    width: 60%;
    font-size: 22px;
  }
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  margin: 0.5rem auto;

  ${media.tablet} {
    margin: 0.4rem 0.6rem;
  }
`;
