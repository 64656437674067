import React, { MouseEvent, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { navigate } from "gatsby";
import { styled, media, fonts } from "../../styles";
import { Link } from "../Link";
import { Image } from "../Image";
import { IconLink } from "../IconLink";
import footerBgImg from "./assets/footer-bg.svg";
import footerGwImg from "./assets/footer-gw-logo.svg";
import { NewsletterModal } from "./NewsletterModal";

const handleLanguageLink = (
  e: MouseEvent,
  locale: "en" | "de",
  newsSlug: { de: string; en: string },
  articleId: string
) => {
  e.preventDefault();

  let to = "/";

  if (
    typeof window !== "undefined" &&
    window.location &&
    window.location.pathname
  ) {
    if (window.location.pathname.includes("/news/")) {
      if (locale === "en" && newsSlug.en.length) {
        navigate(`/en/news/${newsSlug[locale]}`, {
          state: { articleId }
        });
      } else {
        if (locale === "de" && newsSlug.de.length) {
          navigate(`/news/${newsSlug[locale]}`);
        }
      }
      return;
    } else {
      if (locale === "de") {
        to = window.location.pathname.replace("/en/", "/");
      } else if (locale === "en") {
        if (window.location.pathname.startsWith("/en")) {
          to = window.location.pathname;
        } else {
          to = "/en" + window.location.pathname;
        }
      }
    }
  }

  navigate(to);
};

export const Footer: React.FC<{}> = props => {
  useEffect(() => {
    setTimeout(() => {
      console.log(props);
    }, 3000);
  }, []);
  return (
    <FooterContainer>
      <FooterInner>
        <FooterSection id="f_company">
          <FooterHeading>
            <Link to="/">
              <Image
                src={footerGwImg}
                alt="German Wahnsinn GmbH"
                width="140"
                height="48"
              />
            </Link>
          </FooterHeading>

          <span>
            <Trans>
              German Wahnsinn GmbH
              <br />
              Kastanienallee 9, 20359 Hamburg
              <br />
              <a href="mailto:wahnsinn@germanwahnsinn.de">
                wahnsinn@germanwahnsinn.de
              </a>
              <br />
              <a href="tel:+494032536244">+49 40 32 53 62 44</a>
            </Trans>
          </span>
        </FooterSection>

        <FooterSection id="f_language">
          <FooterHeading>
            <Trans>Language</Trans>
          </FooterHeading>

          <ul>
            <li>
              <Link
                to="#"
                onClick={e =>
                  handleLanguageLink(e, "de", props?.articleData?.slug)
                }
                autoLocale={false}
              >
                <Trans>Deutsch</Trans>
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={e =>
                  handleLanguageLink(
                    e,
                    "en",
                    props?.articleData?.slug,
                    props?.articleData?.id
                  )
                }
                autoLocale={false}
              >
                <Trans>English</Trans>
              </Link>
            </li>

            <li>
              <br />
              <NewsletterModal />
            </li>
          </ul>
        </FooterSection>

        <FooterSection id="f_legal">
          <FooterHeading>
            <Trans>Legal</Trans>
          </FooterHeading>

          <ul>
            <li>
              <Link to="/legal/#imprint">
                <Trans>Impressum</Trans>
              </Link>
            </li>
            <li>
              <Link to="/legal/#privacy">
                <Trans>Datenschutz</Trans>
              </Link>
            </li>
            <li>
              <Link to="/legal/#terms">
                <Trans>AGB</Trans>
              </Link>
            </li>
          </ul>
        </FooterSection>

        <FooterSection id="f_departments">
          <FooterHeading>
            <Trans>Departments</Trans>
          </FooterHeading>

          <ul>
            <li>
              <a
                href="https://www.atmende-buecher.de/"
                target="_blank"
                rel="noopener"
              >
                <Trans>Atmende Bücher Hörbuchverlag</Trans>
              </a>
            </li>
            {/* <li>
              <a
                href="http://sprechergesucht.germanwahnsinn.de/"
                target="_blank"
                rel="noopener"
              >
                <Trans>Sprecher Gesucht!</Trans>
              </a>
            </li> */}
            <li>
              <Link to="https://records.germanwahnsinn.de/">
                <Trans>German Wahnsinn Records</Trans>
              </Link>
            </li>
            {/*
            <li>
              <br />
              <Link to="https://vintage.germanwahnsinn.de/">
                <Trans>German Wahnsinn Vintage</Trans>
              </Link>
            </li> */}
          </ul>
        </FooterSection>

        <FooterSection id="f_social">
          <IconLink
            to="https://fb.com/germanwahnsinn"
            target="_blank"
            rel="noopener"
          >
            _
          </IconLink>
          <IconLink
            to="https://www.instagram.com/germanwahnsinn/"
            target="_blank"
            rel="noopener"
          >
            §
          </IconLink>
          <IconLink
            to="https://twitter.com/germanwahnsinn"
            target="_blank"
            rel="noopener"
          >
            #
          </IconLink>
          <IconLink
            to="https://open.spotify.com/artist/2NnaatfEYKcqBk4Qr5OtvP"
            target="_blank"
            rel="noopener"
          >
            *
          </IconLink>
        </FooterSection>
      </FooterInner>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-image: url(${footerBgImg});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 100;
  color: #ffffff;

  margin-top: 3rem;

  a:hover {
    color: inherit;
    font-weight: bold;
  }

  ${media.tablet} {
    margin-top: 5rem;
  }

  ${media.desktop} {
    padding-top: 1%;
  }
`;

const FooterInner = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 5rem 2.5rem 2.5rem 2.5rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${media.landscape} {
    flex-direction: row;
  }

  ${media.tablet} {
    flex-direction: row;
  }

  #f_company {
    flex-basis: 25%;
  }

  #f_social {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;

    ${media.tablet}  {
      display: none;
    }
  }

  @media (max-width: 767px and (orientation: portrait)) {
    li:not(:first-child) {
      margin-left: 0.5rem;

      &:before {
        margin-right: 0.5rem;
        content: "|";
      }
    }

    #f_social {
      order: 1;
    }

    #f_departments {
      order: 2;

      ul {
        display: block;
        list-style-position: outside;
        margin-left: -1rem;

        li {
          margin-left: 0;

          &:before {
            margin-right: 0;
            font-family: ${fonts.vario};
            content: ">";
          }
        }
      }
    }

    #f_social {
      order: 5;
    }

    #f_legal {
      order: 3;

      ul {
        display: flex;
      }
    }

    #f_language {
      order: 4;

      ul {
        display: flex;
      }
    }
  }
`;

// flexBasis={["auto", "auto", "100%", "auto"]}
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;

  width: 100%;

  margin-bottom: 2rem;
  margin-left: 2.5rem;

  ${media.landscape} {
    margin-bottom: 0;
    margin-left: 1.5rem;
  }

  ${media.tablet} {
    width: 25%;
    margin-left: 2.5rem;
  }

  ${media.laptop} {
    margin-bottom: 0;
    margin-left: 0;
  }

  ul {
    margin-bottom: 0;

    ${media.tablet}  {
      display: block;
    }
  }

  &:first-child {
    margin-left: 0;

    h5 {
      display: block;
    }

    img {
      margin-top: -2rem;
    }

    span {
      margin-left: 2.5rem;
      font-style: normal;
    }
  }
`;

const FooterHeading = styled.h5`
  font-size: 1rem;
  margin-bottom: 0.75rem;
  overflow: visible;
  display: none;

  ${media.tablet}  {
    margin-bottom: 1rem;
    display: block;
  }
`;

export default Footer;
