import React, { createContext } from "react";
import { useToggle } from "../../hooks";

export const SidebarContext = createContext<[boolean, () => void]>([
  false,
  (): void => undefined
]);

export const SidebarProvider: React.FC<{}> = ({ children }) => {
  const state = useToggle(false);

  return (
    <SidebarContext.Provider value={state}>{children}</SidebarContext.Provider>
  );
};

// export const SidebarConsumer = SidebarContext.Consumer;
