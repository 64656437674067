import React, { useContext } from "react";
import { styled, css, media, white, black, pink } from "../../../styles";
import { IconLink } from "../../IconLink";
import { SidebarContext } from "../../Sidebar/SidebarContext";

interface IProps {
  width?: string;
  height?: string;
  alt?: string;
}

export const BurgerMenu: React.FC<IProps> = ({ ...props }) => {
  const [isSidebarVisible, toggleSidebar] = useContext(SidebarContext);

  return (
    <BurgerButtonButton
      role="button"
      aria-label="open menu"
      onClick={toggleSidebar}
      open={isSidebarVisible}
    >
      {isSidebarVisible ? `×` : `=`}
    </BurgerButtonButton>
  );
};

const BurgerButtonButton = styled(IconLink)<{ open?: boolean }>`
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-size: 36px;
  margin: 30px 0 0 10px;
  color: ${({ open }) => (open ? white : black)};
  padding-bottom: 5px;

  ${media.tablet} {
    margin: 30px 0 0 30px;
    ${({ open }) =>
      !open &&
      css`
        background-color: rgba(255, 255, 255, 1);
        border-radius: 2px;
        border: 1px solid transparent;
      `};
  }

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ open }) => (open ? white : pink)};
  }

  ${media.tablet} {
    font-size: 40px;
  }
`;
