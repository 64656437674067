import React from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { styled } from "../../styles";

export interface LinkProps extends GatsbyLinkProps<{}> {
  autoLocale?: boolean;
}

const InnerLink = styled.a<LinkProps>``;

const OuterLink: React.FC<LinkProps> = ({
  to,
  autoLocale = true,
  ...props
}) => {
  const localLink = to && (to.startsWith("/") || to.startsWith("#"));
  const hashLink = to && to.startsWith("#");

  let realTo = to;

  if (
    autoLocale !== false &&
    to &&
    to !== "/en" &&
    typeof window !== "undefined" &&
    window.location &&
    window.location.pathname.startsWith("/en")
  ) {
    realTo = `/en${to}`;
  }

  return (
    // @ts-ignore
    <InnerLink
      to={realTo}
      href={localLink && !hashLink ? undefined : to}
      as={localLink && !hashLink ? GatsbyLink : "a"}
      rel={localLink ? undefined : "noopener"}
      target={localLink ? undefined : "_blank"}
      {...props}
    />
  );
};

export const Link = styled(OuterLink)``;

export { navigate } from "gatsby";
