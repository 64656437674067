import React from "react";
import { styled, media } from "../../../styles";
import { Link } from "../../Link";

const SkullImage = styled.img`
  width: 45px;
  height: 60px;
  margin-top: -5px;

  ${media.tablet} {
    margin-right: 0.8rem;
  }
`;

export const Skull: React.FC<{ type: string }> = ({ type }) => {
  return (
    <Link
      to={`/?${type.toLowerCase()}`}
      state={{
        returnFrom: type.toLowerCase()
      }}
    >
      <SkullImage
        alt="Skull"
        src={require(`../assets/HeaderSkull_${type}.svg`)}
      />
    </Link>
  );
};
